<template>
    <div v-if="srcUrl">
        <img :style="[{
            height: height, width: width,
        }, `${rounded ? 'border-radius : 100%;' : ''}`
            , `${radius ? `border-radius:${radius}` : ''}`]" :src="srcUrl"
            v-if="images.includes(srcUrl.split('.').pop())" />
        <div :class="{
            'playing': isPlaying,
            'paused': !isPlaying

        }" style="position: relative;" v-else-if="videos.includes(srcUrl.split('.').pop())">
            <video ref="video" muted="false" :autoplay="autoplay" loop :style="{ height: height, width: width }">
                <source :src="srcUrl" type="video/mp4" />
            </video>
            <v-btn rounded="xl" @click="togglePlay" class="play-btn">
                <v-icon>{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Image',
    props: {
        src: {
            type: String,
            default: null
        },
        height: {
            type: String,
            default: '100%'
        },
        width: {
            type: String,
            default: '100%'
        },
        testimonial: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            domain: window.location.origin,
            images: ['png', 'jpg', 'jpeg', 'webp', 'gif', 'svg'],
            videos: ['mp4', 'webm', 'ogg'],
            isPlaying: false
        }
    },
    computed: {
        srcUrl() {
            if (typeof this.src === 'string' &&
                this.src?.includes('http')) {
                return this.src
            }
            else if (this.testimonial) {

                return this.$r.config.videos + this.src
            }
            else {
                return this.$r.config.videos + 'images/' + this.src
            }
        }
    },
    methods: {
        togglePlay() {
            if (this.$refs.video) {
                if (this.$refs.video.paused) {
                    this.isPlaying = true
                    this.$refs.video.play()
                }
                else {
                    this.isPlaying = false
                    this.$refs.video.pause()
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
img,
video {
    border-radius: 10px;
    display: block;
    margin: 10px;
}

video {
    margin: 0;
}

.play-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #d3a7ff81;
    color: white;
    border: none;
    border-radius: 50% !important;
    padding: 0px !important;
    width: 50px !important;
    height: 64px !important;

    i {
        font-size: 31px;
    }
}
.playing {
    .play-btn {
        transition: all 0.3s;
        opacity: 0;
    }
    &:hover {
        .play-btn {
            opacity: 1;
        }
    }
}
</style>