<template>
    <div> 
        <div v-for="(data, i) in datas.questions">
            <div v-if="i == step">
                <div class="prompt">
                    <h3> {{ data.question }}</h3>
                    <p>
                        {{ data.detail }}
                    </p>
                </div>
                <Webcam @addRecord="nextStep($event, data)" :maxTime="data.max_duration" />
            </div>
        </div>
    </div>
</template>

<script>
import Webcam from '../../../components/Webcam.vue'
export default {
    components: {
        Webcam
    },
    props: {
        datas: Object,
        goStep: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            step: 0
        };
    },
    created() {
        if (this.goStep) {
            this.step = this.datas.questions.findIndex(step => step.id == this.goStep.questionid)
        }
    },
    methods: {
        nextStep(e, data) {
            data.record = e
            data.filmed = true
            this.step++
            if (this.step == this.datas.questions.length) {
                this.$emit('nextStep')
            }
        }
    }
};
</script>

<style scoped lang="scss">
.prompt {
    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 20px;
    }
}
</style>