<template>
    <div class="header d-flex aic jcsb  ">
        <div class="left d-flex aic" @click="$r.goto('/')">
            <img style="width: 150px" :src="require('@/assets/logo.png')" alt="Logo de skooad" class="logo"
                loading="lazy" />
            <h1 style="font-size: 1.5rem;display:none;" class="ml-2">skooad</h1>
        </div>
        <div class="right d-flex aic">
            <v-navigation-drawer v-model="drawer" location="right">
                <v-list v-if="!$r.mobile">
                    <div v-if="!$r.user">
                        <v-list-item v-if="!$r.user" @click="$router.push({ name: 'register' }), drawer = false">
                            <v-list-item-title> Login </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="!$r.user" @click="$router.push({ name: 'register' }), drawer = false">
                            <v-list-item-title> Register </v-list-item-title>
                        </v-list-item>
                    </div>

                    <div v-if="$r.user">
                        <v-list-item @click="$router.push({ name: 'form' }), drawer = false">
                            <v-list-item-title> Testimonial form </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$emit('updateView', 'settings'), drawer = false">
                            <v-list-item-title> Settings</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$r.logout(), drawer = false">
                            <v-list-item-title> Disconnect </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
                <Menu class="pa-2 h100" v-else :linksMenu="linksMenu" :linksMenuBottom="linksMenuBottom" :view="view" />
            </v-navigation-drawer>

            <div class="d-flex aic">
                <v-btn rounded="xl" elevation="0" class="secondary mr-8" size="small">
                    Upgrade
                </v-btn>
                <!-- <img :src="require('@/assets/emojis/wrapped-gift_1f381.png')" alt="Support" style="width: 35px" class="mr-8" />
                <img :src="require('@/assets/emojis/ring-buoy_1f6df.png')" alt="Support" style="width: 35px" class="mr-8" /> -->
                <FluentEmoji class="mr-6" emojiName="wrapped-gift" size="30" />
                <FluentEmoji class="mr-6" emojiName="ring-buoy" size="30" />
                <v-btn color="primary" rounded="xl" @click="drawer = !drawer" size="small" elevation="0" icon="">
                    <img v-if="$r.user?.user_metadata?.picture" :src="$r.user.user_metadata.picture"
                        alt="Profile picture" style="width: 38px; border-radius: 50%;" />
                    <v-icon v-else size="25">
                        mdi-account-circle
                    </v-icon>
                </v-btn>


            </div>
        </div>
    </div>
</template>

<script>
import FluentEmoji from '@/components/utils/FluentEmoji.vue'
import Menu from '@/components/Menu.vue'

export default {
    name: 'Header',
    data() {
        return {
            drawer: false
        }
    },
    props: {
        view: {
            type: String,
            required: true
        },
        linksMenu: {
            type: Array,
            required: true
        },
        linksMenuBottom: {
            type: Array,
            required: true
        }
    },

    components: {
        FluentEmoji,
        Menu
    }
}
</script>

<style scoped lang="scss">
.mobile {
    .logo {
        width: 50px !important;
    }
}

.header {
    z-index: 9;
    background-color: white;
    padding: 1rem;

    .logo {
        cursor: pointer;
    }

    .right {
        .v-btn {
            margin-left: auto;
        }
    }
}
</style>
