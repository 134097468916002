<template>
    <v-app :class="{
        'mobile': isMobile(), 'pc': !isMobile(),
        'noHeaderFooter': noHeaderFooter.includes($route.name)

    }">
        <Header class="header" v-if="!noHeaderFooter.includes($route.name)" :linksMenu="linksMenu"
            :linksMenuBottom="linksMenuBottom" :view="view" @updateView="view = $event" />
        <Message />

        <DialogConfirm @closeDialog="$r.dialogConfirm.is = $event" />

        <dialogQuestions v-if="dialogQuestions && $route.path == '/dashboard/home'" />

        <div class="app">
            <router-view class="router-view" v-if="!$r.loading" :linksMenu="linksMenu"
                :linksMenuBottom="linksMenuBottom" :view="view" @updateView="view = $event" />

        </div>
        <Footer class="footer" v-if="!noHeaderFooter.includes($route.name)" />
    </v-app>
</template>

<script>
import Message from './components/utils/Message.vue'
import Header from './components/Header.vue'
import Footer from './components/FooterV.vue'
import Cookies from './components/utils/Cookies.vue'
import { supabase } from './supabase'
import dialogQuestions from './views/DashboardViews/dialogQuestions.vue'
import DialogConfirm from './components/utils/DialogConfirm.vue'

export default {
    name: 'App',

    components: {
        Message,
        Header,
        Footer,
        Cookies,
        dialogQuestions,
        DialogConfirm
    },

    data() {
        return {
            dialogQuestions: false,
            noHeaderFooter: ['register', 'form', 'testimonial', 'magnet'],
            preferences: [
                {
                    title: 'Performance',
                    description:
                        'Bla bla serviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer serviços que podemos oferecer.',
                    items: [{ label: 'Active', value: 'performance', isRequired: true }]
                },
                {
                    title: 'Analytics',
                    description:
                        'Bla bla serviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer serviços que podemos oferecer.',
                    items: [
                        { label: 'GoogleAnalytics', value: 'ga' },
                        { label: 'Sentry', value: 'sentry', isEnable: true },
                        { label: 'Mapbox', value: 'mapbox' },
                        { label: 'New Relic', value: 'newRelic', isEnable: true },
                        { label: 'Dog Food', value: 'dogfood' }
                    ]
                }
            ],
            view: 'home',
            linksMenu: [
                {
                    active: true,
                    emoji: 'house',
                    name: 'Home',
                    view: 'home'
                },
                {
                    name: 'Magnets',
                    emoji: 'magnet',
                    view: 'magnets',
                    action: '+ Create new magnet',
                    subtitle: 'Capture amazing stories around the world with amazing magnets.'
                },
                {
                    name: 'Stories',
                    emoji: 'purple-heart',
                    view: 'stories',
                    subtitle: 'Stories are the best way to show the world how amazing your product is.'
                },
                {
                    name: 'Spotlights',
                    emoji: 'star',
                    action: '+ Create new spotlight',
                    view: 'spotlights',
                    subtitle: 'Connect magnets to your spotlight to show the best stories.'
                },
                {
                    name: 'Contacts',
                    emoji: 'telephone',
                    view: 'contacts',
                    active: true,
                    subtitle: 'Access all your contacts on this page.'
                },
                {
                    name: 'Studio',
                    emoji: 'clapper-board',
                    view: 'studio',
                    active: false,
                },
                {
                    name: 'Statistics',
                    emoji: 'magnifying-glass-tilted-left',
                    view: 'statistics',
                    active: false,
                },
                {
                    name: 'Settings',
                    emoji: 'cog',
                    view: 'settings',
                    subtitle: 'Update your info and manage your plan. You’ve got the controls!',
                    active: false,
                },
            ],
            linksMenuBottom: [
                {
                    name: 'Request a feature',
                    emoji: 'light-bulb',
                    view: 'feature',
                    subtitle: 'We are always looking for ways to improve our product. Let us know what you need!'
                },
                {
                    name: 'Report a bug',
                    emoji: 'bug',
                    view: 'bug',
                    subtitle: 'We are always looking for ways to improve our product. Let us know what you need!'
                },
                {
                    name: 'What\'s new',
                    emoji: 'rocket',
                    view: 'whatsnew',
                    subtitle: 'Stay up to date with the latest features and improvements.'
                }
            ]
        }
    },
    async mounted() {
        this.setRoute()
    },
    async created() {
        this.$r.loading = true
        await this.loadUser()
        if (this.$r.user) {
            await this.loadMagnet()
            await this.loadStories()
            await this.loadSpotlights()
            await this.loadContacts()
        }
        this.$r.loading = false
    },
    methods: {
        async setRoute(route) {
            if (['/form', '/register', '/testimonial', '/dashboard'].includes(this.$route.path) || this.$route.fullPath == '/') {
                let user = await supabase.auth?.getSession();
                this.$r.user = user?.data?.session?.user;

                if (!this.$r.user && !this.$route.path.includes('/form')) {
                    this.$router.push({ name: 'register' })
                } else if (this.$r.user) {
                    if (this.$route.params.view !== 'home' && this.$route.params.view) {
                        this.view = this.$route.params.view;
                    }
                    this.getUser(this.$r.user.id).then((res) => {
                        if (res.data[0]) {
                            this.$r.user.informations = res.data[0]
                            if (!res.data[0].questionnary_answered) {
                                this.dialogQuestions = true
                            }
                        }
                    })
                }
            }
        },
        async loadMagnet() {
            let magnets = await supabase.from('magnets').select('*, testimonials(*)').eq('user', this.$r.user.id)
            this.$r.magnets = magnets.data
        },
        async loadSpotlights() {
            let spotlights = await supabase.from('spotlights').select('*').eq('user', this.$r.user.id)
            this.$r.spotlights = spotlights.data
        },
        async loadStories() {
            let stories = await supabase.from('testimonials')
                .select('*, magnets(*), contacts(*)')
                .eq('magnets.user', this.$r.user.id)
                .order('created_at', { ascending: false });
            this.$r.stories = stories.data
        },
        async loadContacts() {
            let contacts = await supabase.from('contacts').select('*, testimonials(*)').eq('user', this.$r.user.id)
            this.$r.contacts = contacts.data
        },
        async loadUser() {
            const token = JSON.parse(localStorage.getItem('jwtToken'))
            let user = await supabase.auth?.getSession();
            this.$r.user = user?.data?.session?.user;
        },
        isMobile() {
            window.innerWidth < 700 ? this.$r.mobile = true : this.$r.mobile = false
            return window.innerWidth < 700
        },
        async getUser(id) {
            let user = await supabase.from('users').select('*').eq('id', id)
            return user
        }
    }
}
</script>

<style lang="scss" scoped>
@import './assets/scss/global.scss';
@import './assets/scss/_variables.scss';

:deep(button:not(.rounded-xl)) {
    border-radius: 8px !important;
}

:deep(.bg-primary) {
    background-color: $primary-color !important;
}

.bg-primary {
    background-color: $primary-color !important;
}

:deep(button.secondary),
button.secondary {
    background-color: $secondary-color !important;
    color: #7F56D9 !important;
    font-weight: 800;
}

.primary-color,
:deep(.primary-color) {
    color: #7F56D9 !important;
}

:deep(.v-field) {
    border-radius: 8px !important;
    box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.3) !important; 
}

:deep(.v-field__outline__start),
:deep(.v-field__outline__notch),
:deep(.v-field__outline__end) {
    border-color: #D0D5DD !important; 
    &:before,
    &:after { 
        border-color: #D0D5DD !important;
    }
}
</style>

<style lang="scss">
@import './assets/scss/global.scss';
@import './assets/scss/_variables.scss';

// *:not(.vjs-icon-placeholder):not(circle) {
//     font-family: 'DM Sans', sans-serif;
// }
// all div not in .form
div:not(.form):not(.vjs-icon-placeholder):not(circle) {
    font-family: 'DM Sans', sans-serif;
}

.form * {
    font-family: inherit !important;
}

button.v-btn--variant-outlined {
    .v-btn__content {
        color: $text-primary-color !important;
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $text-primary-color;

}

.v-btn__content {
    text-transform: none !important;
}

.footer {
    padding: 1rem;
    text-align: center;

    a {
        color: $text-primary-color;
        text-decoration: none;
    }
}

.st {
    color: $text-primary-color !important;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
}

.tar {
    text-align: right;
}

.small-text {
    color: $text-primary-color !important;
    font-size: 0.8rem !important;
    opacity: 0.8;
}

.info {
    color: $text-primary-color !important;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
    opacity: 0.8;
}

.app {
    height: -webkit-fill-available;
}



a {
    color: #de6348;
    cursor: pointer;

    &:hover {
        transition: all 0.3s;
        opacity: 0.8;
        transform: scale(1.1);
    }
}

h1,
h2 {
    font-weight: 500;
    color: $text-primary-color;
}

h1 {
    font-size: 1.7rem;

}

h2 {
    font-size: 1.3rem;
    font-weight: 600;
}

.v-btn--variant-outlined {
    background-color: white !important;
}

.router-view {
    height: calc(100vh - 110px);
}

.header {
    background-color: #F5F5F5 !important;
}

.noHeaderFooter {
    .router-view {
        height: 100vh;
    }
}

input {
    color: black !important;
}

label {
    color: black !important;
}

.v-input__details {
    .v-messages__message {
        color: $error-color !important;
    }
}

::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
</style>
