import { createApp, reactive } from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import { loadFonts } from "./plugins/webfontloader"
import axios from "axios"
import vue3GoogleLogin from 'vue3-google-login'
import { supabase } from "./supabase"
import VueGtag from "vue-gtag";
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

loadFonts()

const app = createApp(App)

app.use(VueVideoPlayer)

app.use(vue3GoogleLogin, {
    clientId: '265222787436-4naj0i2ips6thqsvfdf6vnd12f9ivk18.apps.googleusercontent.com'
})

// Define your root data
app.config.globalProperties.$r = reactive({
    config: {
        domain:
            process.env.NODE_ENV === "production" ? "https://app.skooad.com/" : "http://localhost:8080/",
        service_video: process.env.NODE_ENV === "production" ? "https://app.skooad.com/api/" : "http://localhost:3000/",
        videos: process.env.NODE_ENV === "production" ? "https://skooad.com/skooad/testimonial/" : "http://localhost:8080/uploads/",
    },
    message: {
        type: null,
        text: null,
        detail: null,
        delay: 15,
    },
    user: null,
    loading: false,
    mobile: false,

    magnets: [],
    spotlights: [],
    testimonials: [],
    contacts: [],

    dialogConfirm: {
        is: false,
        title: '',
        message: '',
        callback: () => { }
    },

    isMobile() {
        return window.innerWidth < 1000
    },

    timefrom(date) {
        // return x months ago or x days ago or x hours ago or x minutes ago or x seconds ago
        const now = new Date()
        const diff = now - new Date(date)
        const seconds = Math.floor(diff / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)

        if (days > 30) {
            return Math.floor(days /
                30) + ' months ago'
        }
        if (days > 0) {
            return days + ' days ago'
        }
        if (hours > 0) {
            return hours + ' hours ago'
        }
        if (minutes > 0) {
            return minutes + ' minutes ago'
        }
        return seconds + ' seconds ago'

    },

    async deleteTestimonial(id) {
        const response = await supabase.from('testimonials').delete().eq('id', id)
        return response
    },

    async newWidget(id, router) {
        if (!id) {
            const response = await supabase.from('magnets').insert([
                { name: 'My new magnet', user: this.user.id }
            ]).select()
            router.push({ name: 'magnet', params: { id: response?.data[0].id } })
        } else {
            router.push({ name: 'magnet', params: { id } })
        }
    },

    async newSpotlight(id, router) {
        if (!id) {
            const response = await supabase.from('spotlights').insert([
                { name: 'My new spotlight', user: this.user.id }
            ]).select()
            router.push({ name: 'spotlight', params: { id: response?.data[0].id } })
        } else {
            router.push({ name: 'spotlight', params: { id } })
        }
    },

    setMessage(type, text) {
        this.message.type = type
        this.message.text = text
        this.message.delay = 5
    },

    //function return black if the color is light, white if the color is dark
    getTextColor(bgcolor) {
        function hexToRgb(hex) {
            hex = hex.replace(/^#/, '')
            let bigint = parseInt(hex, 16)
            let r = (bigint >> 16) & 255
            let g = (bigint >> 8) & 255
            let b = bigint & 255

            return [r, g, b]
        }

        function luminance(r, g, b) {
            let a = [r, g, b].map(function (v) {
                v /= 255
                return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
            })
            return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
        }

        const rgb = hexToRgb(bgcolor || '#FFFF')
        const lum = luminance(rgb[0], rgb[1], rgb[2])

        return lum < 0.6 ? 'white' : '#403e47'
    },

    formatDate(date, format) {
        if (date) {
            const d = new Date(date)
            let day = d.getDate()
            let month = d.getMonth() + 1
            let year = d.getFullYear()
            if (day < 10) {
                day = '0' + day
            }
            if (month < 10) {
                month = '0' + month
            }
            return format.replace('dd', day).replace('mm', month).replace('yyyy', year)
        } else return null
    },

    async uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, // Generate a random number between 0 and 15
                v = c === 'x' ? r : (r & 0x3 | 0x8); // Use specific values for 'y' (8, 9, A, or B)
            return v.toString(16); // Convert to hexadecimal
        });
    },

    copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            this.setMessage('success', 'Copied to clipboard')
        })
    },

    async updateValueInTable(table, wherekey, wherevalue, column, value) {

        let body = {};
        body[column] = value
        const response = await supabase
            .from(table)
            .update(body)
            .eq(wherekey, wherevalue)
            .select('*')

        return response
    },

    async InsertValueInTable(table, values) {

        try {
            const response = await supabase
                .from(table)
                .insert(values);

            if (response.error) {
                console.error('Error inserting values:', response.error);
                return null;
            }

            console.log('Insert successful:', response);
            return response;
        } catch (error) {
            console.error('Unexpected error:', error);
            return null;
        }
    },

    async getValuesFromTable(table, select, wherekey, wherevalue, getAll = false) {
        let response
        if (!wherekey) {
            response = await supabase
                .from(table)
                .select(select)
        } else {
            response = await supabase
                .from(table)
                .select(select)
                .eq(wherekey, wherevalue)
        }
        if (getAll) return response?.data
        else return response?.data?.[0]?.[select]
    },

    openInNewTab(url) {
        const win = window.open(url, '_blank')
        win.focus()
    },

    async uploadFile(file, lastFile) {
        const formData = new FormData();
        if (!file) {
            const response = await fetch(`${this.config.service_video}delete/file/${lastFile}`, {
                method: 'POST',
                body: formData
            });

            return;

        }
        formData.append('image', file);
        formData.append('uuid', await this.uuid());
        if (lastFile) formData.append('lastFile', lastFile);

        try {
            const response = await fetch(this.config.service_video + 'upload/file', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('File uploaded successfully:', result);
            return result.fileName;
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    },

    async logout() {
        const { error } = await supabase.auth.signOut();
        localStorage.removeItem('jwtToken')
        this.user = null
        router.push('/')
    },

    goto(route) {
        router.push(route)
    }
})

app.use(router).use(vuetify).mount("#app")
