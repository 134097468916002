<template>
    <div class="spotlight d-flex">
        <div class="left" v-if="!loading">
            <div class="head">
                <div class="back">
                    <v-icon class="mb-4" @click="returnBack()">
                        mdi-arrow-left
                    </v-icon>
                </div>

                <div class="title d-flex aic mb-6">
                    <h1 v-if="!nameEdit" class="d-flex aic">
                        <FluentEmoji v-if="activePage?.icon" :emojiName="activePage.icon" />
                        {{ activePage ? activePage.title : spotlight.name }}
                    </h1>
                    <v-icon class="ml-4" v-if="!nameEdit && !activePage"
                        @click="nameEdit = true">mdi-pencil-outline</v-icon>
                    <v-text-field v-if="nameEdit" v-model="spotlight.name" append-icon="mdi-check"
                        @click:append="nameEdit = false, save('name', spotlight.name)"
                        @keyup.enter="nameEdit = false, save('name', spotlight.name)" />
                </div>
            </div>

            <div class="body">
                <div class="pages" v-if="!activePage">
                    <div v-for="page in pages">
                        <div class="page d-flex aic mt-4" @click="changePage(page)">
                            <FluentEmoji class="mr-4" :emojiName="page.icon" />
                            <h3>
                                {{ page.title }}
                            </h3>
                        </div>

                    </div>
                </div>
                <div class="page" v-else>
                    <div>
                        <v-tabs v-model="activePage.tab" v-if="activePage?.form[0].tab">
                            <v-tab v-for="tab in activePage.form" :key="tab.tab">
                                {{ tab.tab }}
                            </v-tab>
                        </v-tabs>

                        <div class="form">
                            <div class="input" v-for="input in form?.fields">
                                <Input @save="inputChange()" :input="input" v-if="input.type !== 'stories'"
                                    :table="'spotlights'" :object="spotlight" :value="spotlight[input.model]" />
                                <div v-else-if="input.type === 'stories'">
                                    <h3 class="mb-4">
                                        Add some love to your spotlight
                                    </h3> 
                                    <div class="testimonials">
                                        <div v-for="testimonial in testimonials" v-if="testTestimonial(testimonial)"
                                            :class="{ 'added': testimonial.added }"
                                            class="mb-4"
                                            >
                                            <TestimonialCard :testimonial="testimonial" :addBtn="true"
                                                @addTestimonial="addTestimonial(testimonial)"
                                                @removeTestimonial="deleteTestimonial(testimonial.id)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right" v-if="!loading">
            <iframe v-if="spotlight" :src="spotlight?.domains?.[0] || 'https://skooad.com/'"
                frameborder="0"></iframe>
            <Widget class="mr-4 mt-4" v-if="spotlight" :spotlightP="spotlight" :editing="true"
                :domain="spotlight?.domains?.[0]" />
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import FluentEmoji from '@/components/utils/FluentEmoji.vue';
import Input from '@/components/inputs/Input.vue';
import TestimonialForm from '../testimonialsInputs/TestimonialForm.vue'; 
import Widget from '../../widgets/App.vue';
import { all } from 'axios';
import { load } from 'webfontloader';
import TestimonialCard from '@/components/TestimonialCard.vue';

export default {
    name: "spotlight",

    components: {
        FluentEmoji,
        Input,
        TestimonialForm,
        Widget,
        TestimonialCard
    },

    computed: {
        activePage() {
            return this.pages.find(page => page.is);
        },
        form() {
            if (this.activePage.form[0].tab) {
                return this.activePage.form[this.activePage.tab];
            } else {
                return this.activePage.form[0];
            }
        }
    },

    data() {
        return {
            goStep: null,
            loadTestimonial: false,
            nameEdit: false,
            spotlight: null,
            loading: true,
            valuesFilters: {
                contact_note: null
            },
            filters: [
                {
                    label: 'Rating',
                    model: 'contact_note',
                    options: [
                        { label: '1 star', value: 1 },
                        { label: '2 stars', value: 2 },
                        { label: '3 stars', value: 3 },
                        { label: '4 stars', value: 4 },
                        { label: '5 stars', value: 5 }
                    ]
                }
            ],
            pages: [{
                title: 'Design',
                icon: 'control-knobs',
                url: 'options',

                form: [
                    {
                        tab: 'Design',
                        fields: [
                            {
                                name: 'Content size',
                                type: 'dropdown',
                                model: 'content_size',
                                options: [
                                    'small',
                                    'medium',
                                    'big'
                                ]
                            }, {
                                name: 'Primary color',
                                type: 'color',
                                model: 'color'
                            },
                            {
                                name: 'Font',
                                type: 'dropdown',
                                model: 'font',
                                options: [
                                    'DM sans',
                                    'Times New Roman', // Serif
                                    'Georgia',         // Serif
                                    'Garamond',        // Serif
                                    'Palatino',        // Serif
                                    'Bookman',         // Serif
                                    'Arial',           // Sans-Serif
                                    'Helvetica',       // Sans-Serif
                                    'Verdana',         // Sans-Serif
                                    'Tahoma',          // Sans-Serif
                                    'Trebuchet MS',    // Sans-Serif
                                    'Geneva',          // Sans-Serif
                                    'Courier New',     // Monospace
                                    'Lucida Console',  // Monospace
                                    'Monaco',          // Monospace
                                    'Comic Sans MS',   // Cursive
                                    'Brush Script MT', // Cursive
                                    'Impact',          // Fantasy
                                    'Charcoal'         // Fantasy
                                ]
                            },
                        ]
                    },
                    {
                        tab: 'Options',
                        fields: [
                        ]
                    }
                ]
            },
            {
                title: 'Stories',
                icon: 'beating-heart',
                url: 'stories',
                form: [
                    {
                        fields: [
                            {
                                name: 'Add some love to your spotlight',
                                type: 'stories'
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Target',
                icon: 'bullseye',
                url: 'target',
                form: [
                    {
                        fields: [
                            {
                                name: 'Domains',
                                type: 'list-string-array',
                                model: 'domains',
                                keys: []
                            }
                        ]
                    }
                ]
            },
            ],
            testimonials: []
        };

    },

    created() {
        if (this.$route.query.page) {
            this.pages.forEach(p => {
                if (p.url === this.$route.query.page) {
                    p.is = true;
                }
            })
            this.changePage(this.pages.find(p => p.url === this.$route.query.page))
        }
        this.loadTestimonials();
        this.loadspotlight(this.$route.params.id);
    },

    methods: {
        testTestimonial(testimonial) {
            for (let key in this.valuesFilters) {
                if (this.valuesFilters[key] && testimonial[key] !== this.valuesFilters[key]) {
                    return false
                }
            }
            return true

        },
        async deleteTestimonial(id) {
            this.testimonials.find(testi => testi.id === id).added = false
            let response = await supabase.from('testisofspot').delete().eq('testimonial', id)
            this.loadspotlight(this.$route.params.id)
        },
        async addTestimonial(testimonial) {
            this.testimonials.find(testi => testi.id === testimonial.id).added = true
            let response = await supabase.from('testisofspot').insert({
                spotlight: this.$route.params.id,
                testimonial: testimonial.id,
                user: this.$r.user.id
            })
            this.loadspotlight(this.$route.params.id)
        },
        inputChange() {
            this.loadTestimonial = true
            this.$nextTick(() => {
                this.loadTestimonial = false
            })
        },
        changePage(page) {
            this.loadTestimonial = true

            this.$router.push({ query: { page: page.url } });
            this.pages.forEach(p => {
                p.is = false;
            })
            page.is = true;

            this.$nextTick(() => {
                this.loadTestimonial = false
            })
        },
        returnBack() {
            if (this.activePage)
                this.pages.find(page => page.is).is = false
            else this.$router.push({ name: 'dashboard', params: { view: 'spotlights' } })
        },
        async loadspotlight(id) {
            this.loading = true;
            const response = await supabase.from('spotlights').select(`*`).eq('id', id).limit(1)
            this.spotlight = response.data[0];
            this.loading = false;
        },
        async loadTestimonials() {
            this.loading = true;
            let allTestimonials = await supabase.from('testimonials').select(`*,contacts(*)`)
            let testiOfSpotlight = await supabase.from('testisofspot').select(`*`).eq('spotlight', this.$route.params.id)
            testiOfSpotlight = testiOfSpotlight.data
            allTestimonials.data.forEach(testimonial => {
                let found = testiOfSpotlight.find(testi => testi.testimonial === testimonial.id)
                if (found) {
                    testimonial.added = true
                }
            })
            this.testimonials = allTestimonials.data;

        },
        actionHeader() {
            this.$router.push({ name: 'dashboard', params: { view: 'spotlights' } });
        },
        async save(field, value) {
            const response = await supabase.from('spotlights').update({ [field]: value }).eq('id', this.$route.params.id)
        },
        async deleteInTable(table, id) {
            const response = await supabase.from(table).delete().eq('id', id)
        }
    }
}
</script>

<style scoped lang="scss">
.spotlight {
    .left {
        padding: 50px;
        width: 50%;
        background-color: #F5F5F5;
    }

    .right {
        border-radius: 10px;
        overflow: hidden;
        width: 50%;

        iframe {
            width: 100%;
            height: 100%;
        }

        .preview {
            min-width: 300px;
            min-height: 300px;
            width: calc(50vw - 500px);
            height: calc(50vw - 500px);
            border: 1px solid #c2c2c2;
            border-radius: 10px;

        }
    }

    .pages {
        max-height: calc(100vh - 350px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 5px;

        .page {
            cursor: pointer;
            border-radius: 10px;
            border: 1px solid #c2c2c2;
            padding: 15px 20px;

            &:hover {
                transition: 0.3s;
                transform: scale(1.01);
                background-color: #f5f5f5;
            }

            &.border-dot {
                border: 1px dashed #c2c2c2;
            }
        }
    }

    .page {
        max-height: calc(100vh - 350px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 20px;
        padding: 10px;
    }
}
</style>