<template>
    <div class="contact-container pb-6">
        <div v-for="inputForm in form">
            <div v-if="isInput(inputForm)" class="mb-2">
                <Input :color="datas.color" :table="'testimonials'" :input="inputForm"
                    :object="testimonial" :value="testimonial?.[inputForm.model]" />
                <div style="font-size: 12px; color: grey; margin-top: 5px;" v-if="inputForm.name == 'Email adress*'">
                    Your email will never be shared
                </div>
            </div>
        </div>
        <v-btn elevation="0" :color="datas.color || 'primary'" block class="mt-4" @click="updateOrInsertContact()">
            Submit
        </v-btn>
    </div>
</template>

<script>
import { supabase } from '../../../supabase';
import Input from '@/components/inputs/Input.vue';

export default {
    components: {
        Input
    },
    props: {
        datas: Object,
        testimonial: Object
    },
    created() {
        this.datas.id = this.$route.params.id
    },
    data() {
        return {
            form: [
                {
                    name: 'Rate us',
                    type: 'stars',
                    model: 'contact_note'
                },
                {
                    name: 'Your name*',
                    type: 'text',
                    model: 'contact_name',
                    update: false
                },
                {
                    name: 'Email adress*',
                    type: 'text',
                    model: 'contact_email',
                    hideDetails: true,
                    update: false
                },
                {
                    name: 'Your photo',
                    type: 'image',
                    model: 'photo',
                    table: 'contacts',
                    update: false
                },
                {
                    name: 'Job title',
                    type: 'text',
                    model: 'contact_job_title',
                    update: false
                },
                {
                    name: 'Your website',
                    type: 'text',
                    model: 'contact_website',
                    update: false
                },
                {
                    name: 'Company name',
                    type: 'text',
                    model: 'contact_company_name',
                    update: false
                },
                {
                    name: 'Company logo',
                    type: 'image',
                    model: 'contact_company_logo',
                    update: false
                }]
        }
    },
    methods: {
        isInput(input) {
            return this.datas[input.model] !== false
        },
        async updateOrInsertContact() {
            if (!this.testimonial.contact_email) {
                this.$r.setMessage('error', 'Please enter your email')
                return
            }
            
            // Test if the contact already exists with email
            const { data, error } = await supabase
                .from('contacts')
                .select('email')
                .eq('email', this.testimonial.contact_email)

            if (error) {
                console.error('error', error)
                return
            }

            const contactData = {
                name: this.testimonial.contact_name,
                email: this.testimonial.contact_email,
                photo: this.testimonial.contact_photo,
                job_title: this.testimonial.contact_job_title,
                website: this.testimonial.contact_website,
                company_name: this.testimonial.contact_company_name,
                company_logo: this.testimonial.contact_company_logo,
                user: this.$r.user.id
            }

            let result
            if (data.length > 0) {
                // Update contact
                result = await supabase
                    .from('contacts')
                    .update(contactData)
                    .eq('email', this.testimonial.contact_email)
            } else {
                // Insert contact and select the id
                result = await supabase
                    .from('contacts')
                    .insert(contactData).select('id')
                    
                this.testimonial.contact = result.data[0].id 
                await supabase
                    .from('testimonials')
                    .update({ contact: result.data[0].id })
                    .eq('id', this.testimonial.id)
            }

            if (result.error) {
                console.error('error', result.error)
            } else {
                this.$emit('nextStep')
            }
        }
    }
};
</script>

<style scoped>
.review-container {
    min-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


video {
    border-radius: 10px;
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}
</style>
