<template>
    <div v-if="!loading">
        <div class="quick-start" v-if="quickStart">
            <div class="d-flex jcsb aic">
                <h2>
                    Quickstart Guide
                </h2>
                <v-icon
                    @click="$r.updateValueInTable('users', 'id', $r.user.id, 'quickstart_guide', false), quickStart = false">
                    mdi-close
                </v-icon>
            </div>

            <div class="quick-start-body d-flex aic ">
                <div class="bloc left">
                    <div v-for="step in steps" class="step d-flex aic mb-2 cp" @click="goto(step.goto)">
                        <v-icon color="primary" class="mr-2">
                            {{ step.test() ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}
                        </v-icon>
                        <p class="st">
                            {{ step.title }}
                        </p>
                    </div>
                </div>
                <div class="bloc chart">
                    <ProgressDoughnut :progress="percentDone == 99 ? 100 : percentDone" />
                </div>
                <!-- <div class="bloc video d-flex fdc aic">
                    <iframe style="border-radius: 10px; max-width: 500px;" height="160"
                        src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <div class="st primary-color mt-2">
                        Watch a 2-minutes guide
                    </div>
                </div> -->
            </div>
        </div>
        <v-btn v-else color="primary" size="small" class="mt-2" rounded="xl"
            @click="$r.updateValueInTable('users', 'id', $r.user.id, 'quickstart_guide', true), quickStart = true">
            Show Quickstart Guide
        </v-btn>
    </div>
</template>

<script>
import ProgressDoughnut from '../../components/utils/ProgressDoughnut.vue'

export default {
    name: 'QuickStart',
    components: {
        ProgressDoughnut
    },
    data() {
        return {
            quickStart: true,
            percentDone: 0,
            loading: false,
            steps: [
                {
                    title: 'Create a magnet',
                    progress: 33,
                    test: () => this.has('magnets'),
                    goto: 'magnets'
                },
                {
                    title: 'Collect your first story',
                    progress: 33,
                    test: () => this.has('stories'),
                    goto: 'stories'
                },
                {
                    title: 'Publish a spotlight',
                    progress: 33,
                    test: () => this.has('spotlights'),
                    goto: 'spotlights'
                }
            ]
        }
    },

    async created() {
        this.loading = true
        this.quickStart = await this.$r.getValuesFromTable('users', 'quickstart_guide', 'id', this.$r.user.id)
        await this.getpercentDone()
        this.loading = false
    },

    methods: {
        goto(step) {
            this.$emit('updateView', step)
            this.$router.push({ name: 'dashboard', params: { view: step } })
        },
        has(type) {
            return this.$r[type].length > 0;
        },
        getpercentDone() {
            let done = 0
            this.steps.forEach(step => {
                if (step.test()) {
                    done += step.progress
                }
            })
            this.percentDone = done
            return
        },

    }
};
</script>

<style lang="scss" scoped>
.quick-start {
    background-color: #fcfaff;
    padding: 1rem;
    border-radius: 10px;
    margin-top: 1rem;

    .quick-start-body {
        .bloc {
            width: 33%;

        }

        .step {
            width: fit-content;
            padding: 0 1rem;

            &:hover {
                background-color: #e8e7e9;
                border-radius: 10px;
            }
        }
    }

}
</style>