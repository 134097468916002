<template>
    <div class="d-flex aic w100">

        <div class="w100" :style="input.style">
            <div class="pro-disabled" v-if="input.pro && !$r.user?.pro">

            </div>
            <!-- STARS -->
            <div :color="color" v-if="input.type === 'stars'">
                <h4>
                    {{ input.name }}
                </h4>
                <div class="d-flex aic mb-6">
                    <div v-for="i in 5" :key="i">
                        <v-icon v-if="i <= val" @click="val = i" :color="color">mdi-star</v-icon>
                        <v-icon v-else @click="val = i">mdi-star-outline</v-icon>
                    </div>
                </div>
            </div>

            <!-- TEXT -->
            <div class="text" v-if="input.type === 'text'">
                <v-text-field :hide-details="input.hideDetails || null" :readonly="proDisabled" v-model="val"
                    variant="outlined" :label="input.name" outlined dense />
            </div>

            <!-- TEXTAREA -->
            <div class="textarea" v-else-if="input.type === 'textarea'">
                <v-textarea :readonly="proDisabled" v-model="val" variant="outlined" :label="input.name" outlined
                    dense />
            </div>

            <!-- VIDEO -->
            <div class="video mb-12" v-else-if="input.type === 'video'">
                <h4>
                    {{ input.name }}
                </h4>
                <ImageStock class="mb-4" width="300px" :src="val" v-if="val" />
                <v-file-input style="display: none" ref="file" hide-details v-model="val" accept="video/*" outlined />
                <v-btn elevation="0" rounded="xl" @click="click()" color="primary" outlined>
                    {{ val ? 'Change' : 'Upload' }}
                </v-btn>
                <v-btn v-if="val" class="ml-4" elevation="0" rounded="xl" @click="val = null" color="error" outlined>
                    Delete
                </v-btn>
            </div>

            <!-- IMAGE -->
            <div class="image d-flex aic jcc fdc wfc" v-else-if="input.type === 'image'">
                <div class="d-flex aic">
                    <div class="d-flex aic" @click="click()">
                        <ImageStock width="100px" :src="val" v-if="val" />
                        <v-btn v-else size="x-large" icon elevation="0"
                            style="border-radius: 50%!important;overflow: hidden;" color="grey" outlined>
                            <v-icon color="white">
                                mdi-image
                            </v-icon>
                        </v-btn>
                        <v-file-input style="display: none" ref="file" hide-details v-model="val" accept="image/*"
                            outlined />
                    </div>
                    <div class="ml-4 info">
                        {{ input.name }}
                    </div>
                </div>
                <v-btn v-if="val" class="ml-4" elevation="0" rounded="xl" @click="val = null" color="error" outlined>
                    Delete
                </v-btn>
            </div>

            <!-- TOGGLE -->
            <div class="toggle" v-else-if="input.type === 'toggle'">
                <h4>
                    {{ input.name }}
                </h4>
                <v-switch :readonly="proDisabled" color="primary" inset density="compact" v-model="val" />
            </div>

            <!-- SLIDER -->
            <div class="slider" v-else-if="input.type === 'slider'">
                <h4>
                    {{ input.name }}
                </h4>
                <div class="d-flex aic">
                    <v-slider :readonly="proDisabled" class="mr-4" color="primary" show-ticks="always" hide-details
                        v-model="val" :max="input.max" :min="input.min || '5'" :step="input.step || 5" />
                    <h5 v-if="val" class="mr-4">
                        <span>{{ val }} {{ input.unit }}</span>
                    </h5>
                </div>
            </div>

            <!-- COLOR -->
            <div class="color mb-6" v-else-if="input.type === 'color'">
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ props }">
                        <div class="d-flex aic ">
                            <div v-bind="props" class="d-flex aic w100">
                                <div v-if="val" class="mr-4"
                                    style="width: 30px; height: 30px; border-radius: 50%; margin-left: 10px;"
                                    :style="{ backgroundColor: val }" />
                                <v-text-field ref="color-input" variant="outlined" hide-details :color="val"
                                    v-model="val" outlined dense :label="input.name" />
                                <v-icon @click="val = '#FFFFFF00'" v-if="val" color="error"
                                    class="ml-4">mdi-close</v-icon>
                            </div>
                        </div>
                    </template>
                    <v-color-picker hide-canvas hide-inputs show-swatches v-model="val" />
                </v-menu>
            </div>

            <!-- DROPDOWN -->
            <div class="dropdown mt-4" v-else-if="input.type === 'dropdown'">
                <v-select v-model="val" :items="input.options" outlined dense :label="input.name" variant="outlined" />
            </div>

            <!-- JSON ARRAY -->
            <div class="json-array" v-else-if="input.type === 'json-array'">
                <h4 class="mb-2">
                    {{ input.name }}
                </h4>
                <div v-for="(item, index) in input.keys">
                    <v-text-field :readonly="proDisabled" hide-details class="mb-4" density="comfortable"
                        @keyup="updateValueAfter" v-model="val[item]" variant="outlined" :label="item" outlined dense />
                </div>
            </div>

            <!-- LIST STRING ARRAY -->
            <div class="list-string-array" v-else-if="input.type === 'list-string-array'">
                <h4 class="mb-2">
                    {{ input.name }}
                </h4>
                <v-btn elevation="0" v-if="val.length === 0" @click="val.push('')" color="primary" text>
                    <v-icon>mdi-plus</v-icon>
                    Add
                </v-btn>
                <div v-for="(item, index) in val" class="d-flex aic mb-4">
                    <v-text-field :readonly="proDisabled" hide-details density="comfortable" @keyup="updateValueAfter"
                        v-model="val[index]" variant="outlined" outlined dense />
                    <v-icon color="primary" class="ml-4" @click="val.splice(index, 1), updateValueAfter()">
                        mdi-trash-can-outline
                    </v-icon>
                    <v-icon color="primary" class="ml-4" @click="val.push('')">mdi-plus-circle-outline</v-icon>
                    <v-icon color="primary" class="ml-4" @click="$r.openInNewTab(val[index])">
                        mdi-open-in-new
                    </v-icon>
                </div>
            </div>
        </div>

        <!-- If askrequired, chekbox to ask for required -->
        <div v-if="input.askRequired" class="d-flex aic mr-4">
            <v-checkbox color="primary" hide-details dense v-model="valRequired" class="mr-6" />
            <span style="cursor: pointer; font-size: 14px; color: grey;"
                @click="valRequired = !valRequired">Required</span>
        </div>

        <div v-if="input.pro">
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                    <v-btn @click="$r.openInNewTab('https://www.google.com')" icon v-bind="props" color="#E8DEF8"
                        elevation="0" size="small" class="mr-2">
                        <b>Pro</b>
                    </v-btn>
                </template>
                <span>Discover our pro plan</span>
            </v-tooltip>
        </div>

    </div>
</template>

<script>
import { supabase } from '@/supabase';
import ImageStock from '@/components/utils/ImageStock.vue'

export default {
    name: 'Input',
    props: {
        input: {
            type: Object,
            default: null
        },
        object: {
            type: Object,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        table: {
            type: String,
            default: 'magnets'
        },
        color: {
            type: String,
            default: 'primary'
        },
        noTimeout: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ImageStock
    },
    computed: {
        proDisabled() {
            return this.input.pro && !this.$r.user.informations?.pro
        }
    },
    watch: {
        async val() {
            if (this.object[this.input.model] !== this.val) {
                if (['text', 'textarea', 'slider'].includes(this.input.type) && this.noTimeout == false) {
                    await this.updateValueAfter()
                } else if (['image', 'video'].includes(this.input.type)) {
                    await this.updateImage()
                } else {
                    await this.updateValue()
                }
            }
            if (this.object[this.input.model] === undefined) this.object[this.input.model] = this.val
        },
        async valRequired() {
            if (this.object[this.input.askRequired] !== this.valRequired) {
                await this.updateRequired()
            }
        }
    },
    data() {
        return {
            timeout: null,
            lastFile: null,
            val: null,
            valRequired: null
        };
    },
    created() {
        this.lastFile = this.object?.[this.input.model]
        this.val = this.value

        if (this.input.askRequired) {
            this.valRequired = this.object?.[this.input.askRequired]
        }

        if (this.input.type === 'json-array') {
            this.val = this.object[this.input.model] || {}
        }
    },
    methods: {
        click() {
            this.$refs.file.click();
        },
        deleteImage() {
            this.val = null
            this.updateImage()
        },

        async updateImage(e) {
            let fileName = await this.$r.uploadFile(this.val, this.lastFile) || null
            if (!this.update == false) {
                this.object[this.input.model] = fileName
                this.val = fileName
                return
            }
            const response = await supabase
                .from(this.input.table || this.table)
                .update({ [this.input.model]: fileName })
                .eq('id', this.object.id || this.$route.params.id);

            this.$emit('save')
            document.location.reload()
        },

        async updateValueAfter() {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(async () => {
                await this.updateValue();
            }, 1000);
        },

        async updateValue() {

            if (this.input.update == false) {
                this.object[this.input.model] = this.val
                return
            }
            let body = {};
            body[this.input.model] = this.val;
            const response = await supabase
                .from(this.input.table || this.table)
                .update(body)
                .eq('id', this.object.id || this.$route.params.id).select('*')
            this.val = response.data[0][this.input.model]
            this.object[this.input.model] = this.val

            this.$emit('save')
            return
        },

        async updateRequired() {
            let body = {};
            body[this.input.askRequired] = this.valRequired;
            await supabase
                .from(this.table)
                .update(body)
                .eq('id', this.object.id || this.$route.params.id).select('*')
            this.object[this.input.askRequired] = this.valRequired
            return
        }
    }
};
</script>

<style scoped lang="scss">
.image {
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.3s;

    &:hover {
        transition: all 0.3s;
        //transform: scale(1.01);
        opacity: 0.9;
    }
}
</style>