<template>
    <div class="mt-4">
        <h2>
            At A Glance
        </h2>
        <!-- {{ $r.stories }}
        <br><br>
        {{ $r.spotlights }} -->
        <div class="blocs">
            <div v-for="bloc in blocs" class="bloc">
                <h2 class="st" style="font-size: 1.1rem!important;">
                    {{ bloc.title }}
                </h2>
                <h1 class="primary-color" style="font-weight: 900;font-size: 2.2rem;">
                    {{ bloc.value() }}
                </h1>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AtAGlance',
    data() {
        return {
            blocs: [
                {
                    title: 'Stories collected',
                    value: () => this.getStoriesCollected()
                },
                {
                    title: 'Stories published',
                    value: () => this.spotlightsPublishedsV
                },
                {
                    title: 'Stories impressions',
                    value: () => this.getStoriesImpressions()
                },
                {
                    title: 'Stories engagements',
                    value: () => this.getStoriesEngagements()
                }
            ],
            spotlightsPublishedsV: 0,
        }
    },
    async created() {
        this.spotlightsPublishedsV = await this.getSpotlightsPublished()
    },
    methods: {
        getStoriesCollected() {
            return this.$r.stories.length
        },
        async getSpotlightsPublished() {
            let nb = await this.$r.getValuesFromTable('testisofspot', 'id', 'user', this.$r.user.id, true)
            nb = nb.length 
            return nb
        },
        getStoriesImpressions() {
            return this.$r.spotlights.reduce((acc, spotlight) => acc + spotlight.views, 0)
        },
        getStoriesEngagements() {
            return this.$r.spotlights.reduce((acc, spotlight) => acc + spotlight.clicks, 0)
        }
    },
}
</script>

<style scoped lang="scss">
.blocs {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .bloc {
        border: 1px solid #e0e0e0;
        padding: 1rem 1.3rem;
        border-radius: 10px;
        margin: 0 10px;
        width: calc(25% - 20px);
    }
}
</style>