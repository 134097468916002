<template>
    <div class="d-flex fdc jcsb h100 pb-6 jcsb">

        <div class="mb-4">
            {{ datas.welcome_message }}
        </div>
 
        <div class="d-flex jcc w100">
            <ImageStock width="400px" :src="datas.welcome_video" />
        </div>
        <div>
            <v-btn @click="$emit('changeType', 'text'), $emit('nextStep')" class="mt-4" type="submit"
                :color="datas.color || 'primary'" variant="outlined" elevation="0" block>
                {{ datas.welcome_label_1 ||'write' }}
            </v-btn>
            <v-btn @click="$emit('changeType', 'video'), $emit('nextStep')" class="mt-4" type="submit"
                :color="datas.color || 'primary'" elevation="0" block>

                {{ datas.welcome_label_2 || 'Record a video' }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { VideoPlayer } from '@videojs-player/vue'
import ImageStock from '@/components/utils/ImageStock.vue';
export default {
    name: '1Welcome',
    components: {
        VideoPlayer,
        ImageStock
    },
    props: {
        datas: {
            type: Object,
            required: true
        }
    }

}
</script>

<style lang="scss" scoped>
.video-js {
    border-radius: 10px;
    overflow: hidden;
    width: calc(100vh - 600px);
    ;
    height: calc(100vh - 600px);
}
</style>