<template>
    <div class="h100">
        <div class="stories w100" v-if="testimonials.length">
            <div v-for="testimonial in testimonials">
                <TestimonialCard :autoplay="false" :testimonial="testimonial" :key="testimonial.id" :deleteBtn="true"
                    @deleteTestimonial="deleteTestimonial" />
                <v-menu offset-y>
                    <!-- <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" density="dense" color="primary" size="x-small">
                            Modify
                        </v-btn>
                    </template> -->
                    <v-list>
                        <v-list-item
                            @click="$r.deleteTestimonial(testimonial.id), testimonials.splice(testimonials.indexOf(testimonial), 1)">
                            <v-list-item-title>
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div v-else class="info h100 w100 d-flex aic jcc fdc" @click="$emit('updateView', 'magnets')">
            <div class="cp">
                No storie yet, share your magnet with the world !

            </div>
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import TestimonialCard from '@/components/TestimonialCard.vue';

export default {
    name: "stories",
    data() {
        return {
            testimonials: []
        };
    },

    components: {
        TestimonialCard
    },

    async created() {
        if (!this.$r.stories) {
            await this.fetchTestimonials();
        } else {
            this.testimonials = this.$r.stories;
        }
    },

    methods: {
        async fetchTestimonials() {
            let { data, error } = await supabase
                .from('testimonials')
                .select('*, magnets(*)')
                .eq('magnets.user', this.$r.user.id)
                .order('created_at', { ascending: false });
            if (error) {
                this.error = error.message;
            } else {
                data = data.filter(testimonial => testimonial.video);
                this.testimonials = data
            }
        },
        async deleteTestimonial(testimonial) {
            let { error } = await supabase
                .from('testimonials')
                .delete()
                .eq('id', testimonial.id);
            if (error) {
                this.error = error.message;
            } else {
                this.testimonials.splice(this.testimonials.indexOf(testimonial), 1);
            }
        }
    },
};
</script>

<style lang="scss">
html,
body {
    overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
.stories {
    display: grid;
    grid-template-columns: repeat(auto-fit, 400px);
    gap: 1rem;
    padding: 1rem;

}
</style>
