<template>
    <div class="testicard">
        <div v-if="testimonial.type == 'video'">
            <div class="top d-flex jcc fdc aic">
                <div class="stars-container">
                    <NoteStar class="stars" v-if="testimonial.contact_note" :note="testimonial.contact_note" />
                </div>
                <div class="video">
                    <ImageStock :autoplay="autoplay" width="400px" :src="testimonial.video" :testimonial="true"
                        :alt="testimonial.contacts?.name" />
                </div>
                <div class="infos-container">
                    <div class="infos">
                        <!-- name -->
                        <h3 v-if="testimonial.contacts?.name">
                            {{ testimonial.contacts?.name }}
                        </h3>
                        <!-- Job title  -->
                        <h4 v-if="testimonial.contacts?.job_title">
                            {{ testimonial.contacts?.job_title }}
                        </h4>
                        <!-- Company name -->
                        <h4 v-if="testimonial.contacts?.company_name">
                            {{ testimonial.contacts?.company_name }}
                        </h4>
                    </div>
                </div>
            </div>
            <div class="bottom mt-4 ml-2 mb-1 d-flex aic jcsb">
                <div class="created_at info" v-if="testimonial.created_at">
                    {{ $r.timefrom(testimonial.created_at) }}
                </div>
                <v-menu offset-y :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    rounded>
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list> 
                        <v-list-item @click="$emit('deleteTestimonial', testimonial)">
                            <v-list-item-title>
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <!-- <div class="bottom">
            <div class="d-flex  aic">
                <div class="logo" v-if="testimonial.contacts?.company_logo">
                    <ImageStock width="70px" height="70px" :src="testimonial.contacts?.photo" :rounded="true"
                        :alt="testimonial.contacts?.name" />
                </div>
                <div class="name">
                    <h3 v-if="testimonial.contacts?.name">
                        {{ testimonial.contacts?.name }}
                    </h3>
                    <div v-if="testimonial.contacts?.job_title || testimonial.contacts?.company_name">
                        {{ testimonial.contacts?.job_title || '?' }} at {{ testimonial.contacts?.company_name || '?' }}
                    </div>
                    <div>
                        <v-chip color="primary" class="white--text mr-2" size="small" v-if="testimonial.contact_note">
                            {{ testimonial.contact_note }} / 5
                        </v-chip>
                        <v-chip color="primary" class="white--text mr-2" size="small"
                            v-if="testimonial.contacts?.email">
                            {{ testimonial.contacts?.email }}
                        </v-chip>
                    </div>
                </div>
            </div>
            <div class="w100 d-flex jcc mt-4">
                <v-btn v-if="addBtn && !testimonial.added" class="mt-4" @click="$emit('addTestimonial', testimonial)"
                    color="primary" fab small>
                    Add in spotlight <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn v-else-if="deleteBtn" class="mt-4" @click="$emit('deleteTestimonial', testimonial)" color="error"
                    fab small>
                    Delete <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn v-else-if="addBtn && testimonial.added" @click="$emit('removeTestimonial', testimonial)"
                    color="error" fab small>
                    Remove from spotlight <v-icon>mdi-delete</v-icon>
                </v-btn>
            </div>
            <div class="d-flex jcse mt-4">
                <div class="cta" v-if="testimonial.contacts?.website">
                    <a @click="$r.openInNewTab(testimonial.contacts?.website)" target="_blank">{{
                        testimonial.contacts?.website }}</a>
                </div>
                <div class="created_at info" v-if="testimonial.created_at">
                    {{ $r.timefrom(testimonial.created_at) }}
                </div>
            </div>
        </div> -->
            <!-- {{ testimonial }} -->
        </div>
        <div v-else-if="testimonial.type == 'text'">
            <!-- {{ testimonial }} -->
            <NoteStar class="stars" v-if="testimonial.contact_note" :note="testimonial.contact_note" />
            {{ testimonial.contacts?.photo }}
            <ImageStock width="70px" height="70px" :src="testimonial.contacts?.photo" :rounded="true"
                :alt="testimonial.contacts?.name" />
        </div>
    </div>
</template>

<script>
import ImageStock from './utils/ImageStock.vue';
import NoteStar from './utils/NoteStar.vue';
import { supabase } from '../supabase';
export default {
    props: {
        testimonial: {
            type: Object,
            default: null,
        },
        addBtn: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        deleteBtn: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ImageStock,
        NoteStar
    },
    async mounted() {
        if (this.testimonial.type == 'text') {
            this.getQuestions();
        }
    },
    methods: {
        async getQuestions() {
            let res = await supabase.from('questions').select('*').eq('magnet', this.testimonial.magnets.id);

            this.testimonial.text_answers.map((item) => {
                item.question = res.data.find(question => question.id == item.question_id)?.question
            });

        }
    }
}
</script>

<style scoped lang="scss">
.testicard {
    border-radius: 10px;
    width: 400px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);

    .stars-container {
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .stars {
            padding-left: 10px;
            transform: translateY(10px);
            height: 0px;
        }
    }

    .bottom {
        padding: 10px;
        padding-top: 0;
    }

    :deep(video) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .infos-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        height: 0;
        transform: translateY(-75px);
        color: #fff !important;
        padding-left: 15px;

        h3 {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
            color: #fff;
        }

        h4 {
            font-size: 0.8rem;
            font-weight: 400;
            margin: 0;
            color: #fff;
        }
    }
}
</style>