<template>
    <div id="my-widget" class="my-widget">
        <div class="testimonial-video" v-if="videoHover">
            <TestimonialCard :testimonial="videoHover" />
        </div> 
        <div v-if="testimonials.length" class="bubbles"
            :style="{ 'background-color': spotlight.color || 'rgba(0, 0, 0, 0.8)' }">
            <div v-for="testimonial in testimonials" :class="{ 'selected': videoHover === testimonial }"
                :key="testimonial.id" @mouseenter="videoHover = testimonial, addView()" class="bubble">
                <div class="video-container">
                    <video :src="`${$r.config.videos}${testimonial.video}`" :controls="false"
                        @click="playVideo(testimonial)" />
                </div>
            </div>
        </div>
        <div v-else-if="error" class="error">
            <p>{{ error }}</p>
            <p>
                Visit the <a target="_blank" href="http://skooad.com/">Skooad dashboard</a> to configure your spotlight.
            </p>
        </div>
    </div>
</template>

<script>
import TestimonialCard from '@/components/TestimonialCard.vue';
export default {
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
        domain: {
            type: String,
            default: null,
        },
        spotlightP: {
            type: Object,
            default: null,
        },
    },
    components: {
        TestimonialCard,
    },
    data() {
        return {
            testimonials: [],
            allTestimonials: [],
            videoHover: null,
            spotlight: null,
            error: null,
            viewPlus: false
        };
    },
    async mounted() {
        await this.fetchTestimonials();
    },
    methods: { 
        async fetchSupa(table, where, join) {
            const supabaseUrl = 'https://ibbtamdgytgjscntvzzc.supabase.co';
            const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImliYnRhbWRneXRnanNjbnR2enpjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzMzY1NTEsImV4cCI6MjAzNjkxMjU1MX0.QApkLafdFRU0mgGgbqZrNDcMRn55-WYocsTyVYvxbGs';

            const selectClause = join ? `*,${join}(*)` : '*';
            const url = `${supabaseUrl}/rest/v1/${table}?select=${selectClause}${where ? `&${where}` : ''}`;

            const response = await fetch(url, {
                headers: {
                    apikey: supabaseKey,
                    Authorization: `Bearer ${supabaseKey}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                console.error(`Error fetching data from ${table}:`, response.statusText);
                return [];
            }

            return response.json();
        },
        async addView() {
            if (!this.spotlight || this.viewPlus) return;
            const views = this.spotlight.views + 1;
            this.viewPlus = true;
            this.patchSupa('spotlights', `id=eq.${this.spotlight.id}`, 'views', views);
        },
        patchSupa(table, where, key, value) {
            const supabaseUrl = 'https://ibbtamdgytgjscntvzzc.supabase.co';
            const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImliYnRhbWRneXRnanNjbnR2enpjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzMzY1NTEsImV4cCI6MjAzNjkxMjU1MX0.QApkLafdFRU0mgGgbqZrNDcMRn55-WYocsTyVYvxbGs';

            const response = fetch(`${supabaseUrl}/rest/v1/${table}?${where}`, {
                method: 'PATCH',
                headers: {
                    apikey: supabaseKey,
                    Authorization: `Bearer ${supabaseKey}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    [key]: value,
                }),
            });
        },
        async fetchTestimonials() {
            let user = document.querySelector('#my-widget').getAttribute('user') || '7442ef69-9b33-4248-8ce5-606f1267a472'
            if (this.spotlightP) this.spotlight = this.spotlightP;

            if (this.spotlight == null) {
                let currentDomain = this.domain || document.location.href;

                // Construct the Supabase query
                let spotlightResponse = await this.fetchSupa(
                    'spotlights',
                    `user=eq.${user}&active=eq.true`,
                    null
                );
                //filter by domain with spotlightResponse.domains wich is array of domains string
                spotlightResponse = spotlightResponse.filter((spotlight) => {
                    return spotlight.domains.includes(currentDomain);
                })

                if (spotlightResponse && spotlightResponse.length) {
                    this.spotlight = spotlightResponse[0];
                } else {
                    console.error('No spotlights found for this domain.');
                    //this.error = 'No spotlights found for this domain.';
                    return;
                }
            }
            //tests
            if (!this.spotlight) {
                console.error('No spotlights found for this domain.');
                return;
            }


            let testimonials = await this.fetchSupa('testisofspot', `spotlight=eq.${this.spotlight.id}`, 'testimonials')
            let allTestimonials = await this.fetchSupa('testimonials', null, null)
            
            this.testimonials = testimonials.map((t) => {
                let testimonial = allTestimonials.find((at) => at.id === t.testimonial);
                return testimonial;
            });



        },
        playVideo(testimonial) {
            const videoUrl = `${testimonial.video}/`;
            const videoPlayer = document.createElement('video');
            videoPlayer.src = videoUrl;
            videoPlayer.controls = true;
            videoPlayer.autoplay = true;
            videoPlayer.style.position = 'fixed';
            videoPlayer.style.left = '50%';
            videoPlayer.style.top = '50%';
            videoPlayer.style.transform = 'translate(-50%, -50%)';
            videoPlayer.style.zIndex = '10000';
            videoPlayer.style.width = '80%';
            videoPlayer.style.height = '80%';
            document.body.appendChild(videoPlayer);

            videoPlayer.addEventListener('click', () => {
                document.body.removeChild(videoPlayer);
            });
        },
    },
};
</script>

<style lang="scss">
$taille-bulle : 50px;

.error {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5px 10px;
    border-radius: 10px;

    p {
        color: white;
    }

}

.my-widget {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;

    .bubbles {
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 5px 10px;
        border-radius: 10px;
    }

    .testimonial-video {
        position: absolute;
        right: 90px;
        border-radius: 10px;
        overflow: hidden;
        top: calc(50% - 150px);
        height: fit-content;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        video {
            height: 100%;
        }
    }

    .video-container {
        width: $taille-bulle;
        height: $taille-bulle;
        overflow: hidden;
        border-radius: 100%;
        margin: 10px 0;

        video {
            width: auto;
            height: $taille-bulle;
        }

    }

    .bubble.selected {
        transform: scale(1.1);
        transition: transform 0.5s;
    }
}
</style>
