import { createRouter, createWebHistory } from "vue-router";
import Register from "../views/User/Register.vue";
import PrivacyPolicy from "../views/conditions/PrivacyPolicy.vue";
import CGV from "../views/conditions/CGV.vue";
import MentionsLegales from "../views/conditions/MentionsLegales.vue";
import TestimonialForm from "../views/testimonialsInputs/TestimonialForm.vue";
import Review from "../views/testimonialsInputs/Review.vue";
import Dashboard from "@/views/Dashboard.vue";
import Magnet from "@/views/Magnet/Magnet.vue"
import ShowTestimonial from "@/views/Testimonial/ShowTestimonial.vue";
import Spotlight from "@/views/Spotlight/Spotlight.vue";

const routes = [
    // User routes
    {
        path: "/user/register",
        name: "register",
        component: Register,
        meta: { title: 'Register' }
    },
    {
        path: "/dashboard/:view?",
        name: "dashboard",
        component: Dashboard,
        meta: { title: 'Dashboard' }
    },
    {
        path: "/form/:id",
        name: "form",
        component: TestimonialForm,
        meta: { title: 'Testimonial Form' }
    },
    {
        path: "/testimonial/:id",
        name: "testimonial",
        component: ShowTestimonial,
        meta: { title: 'Testimonial' }
    },
    {
        path: "/magnet/:id",
        name: "magnet",
        component: Magnet,
        meta: { title: 'Magnet' }
    },
    {
        path: "/spotlight/:id",
        name: "spotlight",
        component: Spotlight,
        meta: { title: 'spotlight' }
    },
    {
        path: "/review/:id",
        name: "review",
        component: Review,
        meta: { title: 'Review' }
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: { title: 'Privacy Policy' }
    },
    {
        path: "/widget",
        name: "widget",
        component: () => import("../widgets/App.vue"),
        meta: { title: 'Widget' }
    },
    {
        path: "/cgv",
        name: "cgv",
        component: CGV,
        meta: { title: 'CGV' }
    },
    {
        path: "/mentions-legales",
        name: "mentions-legales",
        component: MentionsLegales,
        meta: { title: 'Mentions Legales' }
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/dashboard/home",
    }
];

const router = createRouter({
    history: createWebHistory(process.env.NODE_ENV === "production" ? "/" : "/"),
    routes,
});

export default router;
