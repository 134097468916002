<template>
    <div class="magnet h100">
        <div class="magnets-list d-flex aic mb-6" v-if="magnets.length > 0">
            <div class="magnet mr-4" v-for="magnet in magnets?.sort((a, b) => a.created_at < b.created_at ? 1 : -1)"
                :key="magnet.id">
                <div class="head d-flex aic jcsb">
                    <div class="left d-flex aic">
                        <h4>
                            {{ magnet.name }}
                        </h4>
                        <div class="status ml-2" :style="{ backgroundColor: magnet.active ? '#4CAF50' : '#F44336' }" />
                    </div>
                    <div class="right">
                        <v-icon @click="$r.openInNewTab(formUrl(magnet))">
                            mdi-open-in-new
                        </v-icon>
                        <v-menu offset-y :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                            rounded>
                            <template v-slot:activator="{ props }">
                                <v-btn icon v-bind="props" variant="text">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="actionHeader(magnet.id)">
                                    <v-list-item-title> Edit </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeStatus(magnet.id)" v-if="!magnet.active">
                                    <v-list-item-title>
                                        Activate the magnet
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="changeStatus(magnet.id)" v-else>
                                    <v-list-item-title>
                                        Deactivate the magnet
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="dialogConfirm(magnet.id)">
                                    <v-list-item-title> Delete </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
                <div class="bottom">
                    <div class="blocs d-flex jcsb">
                        <div class="views ma-4 d-flex fdc aic ">
                            <v-icon>
                                mdi-eye-outline
                            </v-icon>
                            <p class="small-text">
                                {{ magnet.viewed }} View{{ magnet.viewed > 1 ? 's' : '' }}
                            </p>
                        </div>
                        <div class="stories ma-4 d-flex fdc aic">
                            <v-icon>
                                mdi-heart-outline
                            </v-icon>
                            <p class="small-text">
                                {{ magnet.testimonials.filter(testimonial => testimonial.contact_name).length }} Stories
                            </p>
                        </div>
                        <div class="answers ma-4 d-flex fdc aic">
                            <v-icon>
                                mdi-percent-outline
                            </v-icon>
                            <p class="small-text">
                                {{ Math.floor(magnet.testimonials.filter(testimonial => testimonial.contact_name).length
                                    / magnet.viewed
                                    * 100) || 0 }} % Answers
                            </p>
                        </div>
                    </div>
                    <div class="buttons ma-4 d-flex jce aic">
                        <v-icon @click="shareDialog.magnet = magnet, shareDialog.display = true" color="primary"
                            variant="outlined" rounded="xl" elevation="0" class="mr-6">
                            mdi-share-variant-outline
                        </v-icon>
                        <v-btn color="primary" rounded="xl" elevation="0" class="secondary"
                            @click="$router.push({ name: 'stories', params: { id: magnet.id } })">
                            See stories
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="info h100 w100 d-flex aic jcc fdc">
            No magnet yet, create one to collect stories !
        </div>


        <div class="head">
            <h1>
                Start with a template
            </h1>
        </div>

        <div class="templates">
            <div v-for="template in templates" :key="template.title" class="template d-flex aic jcsb mt-6 pb-4">
                <div class="left">
                    <h4>
                        {{ template.title }}
                    </h4>
                    <p>
                        {{ template.description }}
                    </p>
                </div>
                <div class="right">
                    <v-btn color="primary" rounded="xl" elevation="0" class="secondary"
                        @click="createWTemplate(template)">
                        Create magnet
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- <div class="better-stories ma-6 mt-12 d-flex aic">
            <div class="left mr-6">
                <img style="width: 150px" :src="require('@/assets/logo.png')" alt="Logo de skooad" class="logo"
                    loading="lazy" />
            </div>
            <div class="right">
                <h2>
                    Get better stories
                </h2>
                <p class="mb-6">
                    View over 50 prefect questions and add them to your form, wether is form Saas, Ecommerce or
                    whatever!
                </p>
                <v-btn color="primary" @click="actionHeader" variant="outlined" rounded="xl" elevation="0">
                    Create magnet
                </v-btn>
            </div>
        </div> -->

        <!-- SHARE DIALOG -->
        <v-dialog width="600" v-model="shareDialog.display">
            <div class="dialog-share">
                <div class="head d-flex w100 jcc aic">
                    <FluentEmoji class="mr-4" size="50" emojiName="party-popper" />
                    <h1>
                        Share your magnet !
                    </h1>
                </div>
                <div class="body">
                    <div class="copy">
                        <div class="link">
                            <h4 class="mb-4 mt-4">
                                Click to copy and share your magnet link
                            </h4>
                            <div class="link-input">
                                {{ formUrl() }}
                                <v-icon @click="$r.openInNewTab(formUrl())">
                                    mdi-open-in-new
                                </v-icon>
                                <v-icon @click="$r.copyToClipboard(formUrl())">
                                    mdi-content-copy
                                </v-icon>
                            </div>
                        </div>
                    </div>
                    <div class="domain">

                    </div>
                    <div class="bottom d-flex aic jcse">
                        <div class="left d-flex jcc fdc aic">
                            <h4 class="mb-4">
                                Share a QR code
                            </h4>
                            <QRcode :url="formUrl()" />
                        </div>
                        <div class="right d-flex jcc fdc">
                            <h4 class="mb-4">
                                Embed on your website
                            </h4>
                            <v-btn @click="copyIframe()" rounded="xl" elevation="0" color="primary" class="ml-4">
                                Copy code
                            </v-btn>
                        </div>
                    </div>
                </div>
                <!-- {{ shareDialog.magnet }} -->
            </div>
        </v-dialog>
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import FluentEmoji from '@/components/utils/FluentEmoji.vue';
import QRcode from '@/components/utils/QRcode.vue';

export default {
    name: "Magnet",
    data() {
        return {
            shareDialog: {
                display: false,
                magnet: null
            },
            magnets: [],
            templates: [
                {
                    title: 'Testimonials',
                    description: 'Use a magnet specifically designed to collect testimonials.',
                },
                {
                    title: 'Candidate screening',
                    description: 'Use a magnet tailored to screen your candidates.',
                },
                {
                    title: 'Team member interview',
                    description: 'Use a magnet to conduct interviews for your employer branding and marketing.',
                }
            ],
            loading: false,
            nbStories: 0
        };
    },

    created() {
        this.loadMagnets()

    },


    components: {
        FluentEmoji,
        QRcode
    },

    methods: {
        dialogConfirm(id) {
            this.$r.dialogConfirm = {
                is: true,
                title: 'Delete magnet',
                message: 'Are you sure you want to delete this spotlight ? This action cannot be undone.',
                callback: () => this.deleteMagnet(id)
            }
        },
        copyIframe() {
            const iframe = `<iframe src="${this.formUrl()}" width="100%" height="500px" frameborder="0"/>`
            this.$r.copyToClipboard(iframe)
        },
        async createWTemplate(template) {
            //duplicate the template where name = template.title from table magnetstemplates in magnets
            let res = await supabase.from('magnetstemplates').select('*').eq('name', template.title)
            let magnet = res.data[0]
            magnet.user = this.$r.user.id
            delete magnet.id
            delete magnet.created_at

            let { data, error } = await supabase.from('magnets').insert([magnet]).select('*')

            if (error) {
                console.error(error)
                return
            }

            this.$router.push({ name: 'magnet', params: { id: data[0].id } })
        },
        formUrl(magnet) {
            return `${this.$r.config.domain}form/${this.shareDialog.magnet?.id || magnet.id}`
        },
        async changeStatus(id) {
            const magnet = this.magnets.find(magnet => magnet.id === id);
            await supabase.from('magnets').update({ active: !magnet.active }).eq('id', id);
            this.loadMagnets();
        },
        async actionHeader(id) {
            this.$r.newWidget(id, this.$router)
        },
        async deleteMagnet(id) {
            await supabase.from('magnets').delete().eq('id', id)
            this.magnets = this.magnets.filter(magnet => magnet.id !== id);
            this.$r.setMessage("info", "Your magnet has been deleted")
        },
        async loadMagnets() {
            const response = await supabase.from('magnets').select('*, testimonials(*)').eq('user', this.$r.user.id);
            this.magnets = response.data
            return response.data
        }
    },
};
</script>

<style lang="scss">
html,
body {
    overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
.magnet {
    margin-top: 10px;
    min-width: 320px;

    .template {
        border: 1px solid #E8DEF8;
        border-radius: 10px;
        padding: 1rem;
    }

    h4 {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .better-stories {
        border: 1px solid #E8DEF8;
        border-radius: 10px;
        width: fit-content;
        padding: 1rem;

    }


    .magnets-list {
        max-width: calc(100% - 000px);
        overflow-x: auto;

        .magnet {
            width: 350px;
            border-radius: 10px;
            border: 1px solid #E8DEF8;

            .head {
                padding: 5px 15px;

                .status {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
            }

            .bottom {
                padding: 5px 10px;
                background-color: #fafafa;
                border-radius: 10px;
                margin: 0 10px 10px 10px;
            }
        }
    }
}

.dialog-share {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;
    min-width: 600px;
    max-width: 600px;

    .body {
        .copy {
            .link {
                .link-input {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #E8DEF8;
                    border-radius: 5px;
                    padding: 10px 10px;
                    font-size: 14px;

                    v-icon {
                        cursor: pointer;
                    }
                }
            }
        }

        .bottom {
            padding: 20px;
            margin-top: 20px;
            border-radius: 5px;
            background-color: #F3EDF7;

        }
    }
}
</style>
