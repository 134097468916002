<template>
    <div>
        <div v-for="(data, i) in datas.questions">
            <div v-if="i == step">
                <div class="prompt">
                    <h3> {{ data.question }}</h3>
                    <p>
                        {{ data.detail }}
                    </p>
                </div>
                <Input v-if="!loading" :noTimeout="true" :object="object" :input="input" />
            </div>
        </div>
        <v-btn @click="nextStep" class="mt-4" type="submit" :color="datas.color || 'primary'" elevation="0" block>
            Next
        </v-btn>
    </div>
</template>

<script>
import Input from '../../../components/inputs/Input.vue'
import { supabase } from '../../../supabase'
export default {
    components: {
        Input
    },
    props: {
        datas: Object,
        goStep: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            step: 0,
            answers: [],
            object: {
            },
            loading: false,
            input: {
                name: 'Your answer',
                type: 'textarea',
                model: 'text_answers',
                hideDetails: true,
                update: false
            }
        };
    },
    created() {
        this.datas.questions.forEach((question, i) => {
            this.object[`text_answers${i}`] = ''
        })
        this.input.model = `text_answers${this.step}`
        if (this.goStep) {
            this.step = this.datas.questions.findIndex(step => step.id == this.goStep.questionid)
            this.input.model = `text_answers${this.step}`
        }
    },
    methods: {
        async nextStep(e, data) {
            this.loading = true
            this.answers.push({
                question_id: this.datas.questions[this.step].id,
                answer: this.object[`text_answers${this.step}`]
            })
            this.step++
            this.input.model = `text_answers${this.step}`
            this.loading = false
            if (this.step == this.datas.questions.length) {
                debugger
                const { data, error } = await supabase
                    .from('testimonials')
                    .update({ text_answers: this.answers })
                    .eq('id', this.$route.query.testimonials_id)

                this.$emit('nextStep', 2)
            }
        }
    }
};
</script>

<style scoped lang="scss">
.prompt {
    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 20px;
    }
}
</style>