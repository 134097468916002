<template>
    <div>
        <div class="cam">
            <div class="time-container" v-if="isRecording">
                <div class="time">
                    Recording - {{ msws(time.passed) }}/{{ msws(time.max) }}
                </div>
            </div>

            <video muted ref="video" autoplay playsinline v-if="recordedChunks.length == 0"></video>

            <div class="countdown" v-if="countdown.active">
                {{ countdown.i }}
            </div>

            <video ref="recordedVideo" controls v-if="!isRecording && recordedChunks.length > 0"></video>

            <div class="rec-container" v-if="recordedChunks.length == 0 && !countdown.active">
                <div class="rec" @click="isRecording ? stopRecording() : countdownPlay()">
                    <div class="rec-off" v-if="!isRecording" />
                    <div v-else class="rec-on" />
                </div>
            </div>

        </div>

        <div class="w100 d-flex aic mt-4" v-if="recordedChunks.length > 0">
            <v-btn style="width: calc(50% - 10px)" elevation="0" @click="reset">
                Redo
            </v-btn>
            <v-btn style="width: calc(50% - 10px)" elevation="0" color="primary" @click="$emit('addRecord', blob)">
                Next
            </v-btn>
        </div>

        <!-- Transcript Display -->
        <div v-if="transcript" class="transcript">
            <h3>Transcript:</h3>
            <p>{{ transcript }}</p>
        </div>
    </div>
</template>

<script>
import { VideoPlayer } from '@videojs-player/vue'

export default {
    components: {
        VideoPlayer
    },
    props: {
        maxTime: {
            type: Number,
            default: 30
        }
    },
    data() {
        return {
            blob: null,
            mediaStream: null,
            mediaRecorder: null,
            recordedChunks: [],
            isRecording: false,
            video: null,
            countdown: {
                i: 3,
                active: false
            },
            time: {
                max: this.maxTime,
                passed: 0
            },
            transcript: '',
            recognition: null
        };
    },
    mounted() {
        this.setupWebcam();
    },
    methods: {
        async setupWebcam() {
            const constraints = {
                video: {
                    width: { ideal: 640, max: 640 },
                    height: { ideal: 480, max: 480 }
                },
                audio: true
            };
            try {
                this.mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
                this.$refs.video.srcObject = this.mediaStream;
            } catch (error) {
                console.error('Error accessing webcam:', error);
            }
        },
        async countdownPlay() {
            this.countdown.active = true
            let j = process.env.NODE_ENV === "production" ? 0 : 3
            for (let index = 3; index > j; index--) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                this.countdown.i--;
            }
            this.countdown.active = false
            this.startRecording()
        },
        calcTimePassed() {
            if (this.isRecording) {
                setTimeout(() => {
                    this.time.passed++
                    this.calcTimePassed()
                }, 1000);
            }
            if (this.time.passed > this.time.max) {
                this.stopRecording()
            }
        },
        startRecording() {
            this.recordedChunks = [];
            this.mediaRecorder = new MediaRecorder(this.mediaStream);
            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.recordedChunks.push(event.data);
                }
            };
            this.mediaRecorder.onstop = () => {
                this.stopRecording()
            };
            this.mediaRecorder.start();
            this.isRecording = true;

            // Initialize SpeechRecognition
            this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.onresult = (event) => {
                let interimTranscript = '';
                for (let i = event.resultIndex; i < event.results.length; i++) {
                    if (event.results[i].isFinal) {
                        this.transcript += event.results[i][0].transcript;
                    } else {
                        interimTranscript += event.results[i][0].transcript;
                    }
                }
                this.transcript += interimTranscript;
                console.log(this.transcript);

            };
            
            this.recognition.start();

            this.calcTimePassed()
        },
        stopRecording() {
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
                this.isRecording = false;
            }
            if (this.recognition) {
                this.recognition.stop();
            }
            setTimeout(() => {
                this.blob = new Blob(this.recordedChunks, { type: 'video/webm' });
                this.$refs.recordedVideo.src = URL.createObjectURL(this.blob);
            }, 100);
        },
        reset() {
            this.isRecording = false
            this.recordedChunks = [];
            this.setupWebcam();
            this.countdown.i = 3
            this.transcript = '';
        },
        msws(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        }
    }
};
</script>

<style scoped lang="scss">
.mobile {
    .countdown {
        top: -250px !important;
    }
}

video {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}

button {
    margin: 5px;
}

.time-container {
    position: relative;
    height: 0;
    left: 5px;
    top: 5px;
    width: fit-content;

    .time {
        padding: 2px 10px;
        background-color: white;
        border-radius: 15px;
        font-size: 14px;
    }
}

.cam {
    border-radius: 10px;
    overflow: hidden;

    video {
        margin: 0
    }
}

.countdown {
    display: flex;
    justify-content: center;
    position: relative;
    top: -330px;
    height: 0;
    color: white;
    font-size: 150px;
    font-weight: bold;
}

.rec-container {
    height: 0;
    width: 0;
    position: relative;
    top: -80px;
    left: calc(50% - 35px);

    .rec {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 5px solid white;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        .rec-off {
            background-color: #DB4A2B;
            width: 60px;
            height: 60px;

            border-radius: 50%;
            padding: 5px;
        }

        .rec-on {
            background-color: #DB4A2B;
            width: 30px;
            height: 30px;
            border-radius: 5px;
        }
    }

}

.reset {
    width: 100%;
    height: 0px;

    .v-icon {
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        font-size: 25px;
        color: white;
        font-weight: bold;
        position: relative;
        top: 10px;
        left: 10px;
    }
}

.transcript {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>