<template>
    <div>
        <div class="stars d-flex ">
            <div v-for="i in 5" :style="`filter:brightness(${i > note ? 0.2 : 1})`">
                <FluentEmoji emojiName="star" />
            </div>
        </div>
    </div>
</template>

<script>
import FluentEmoji from './FluentEmoji.vue';
export default {
    props: {
        note: {
            type: Object,
            default: null,
        },
    },
    components: {
        FluentEmoji
    },
    data() {
        return {
        };
    },
    async mounted() {
    },
    methods: {
    },
};
</script>