<template>
    <div class="h100 mt-4">
        <v-text-field prepend-inner-icon="mdi-magnify" density="compact" variant="outlined" style="width: 300px;"
            v-model="search" @input="searchContacts" placeholder="Search contacts" />
        <div class="contacts w100" v-if="contacts.length">
            <div v-for="contact in contactF" class="contact d-flex aic mt-2 jcsb pr-6 pt-2 pb-2">
                <div class="d-flex aic">
                    <div class="photo ml-4">
                        <ImageStock :src="contact.photo" width="50px" rounded="true" />
                    </div>
                    <div class="infos d-flex fdc ml-4">
                        <div class="st">
                            {{ contact.name }}
                        </div>
                        <div class="small-text">
                            {{ contact.job_title }}
                        </div>
                        <div class="small-text">
                            {{ contact.company_name }}
                        </div>
                    </div>
                </div>
                <div class="right st primary-color tar">
                    <div>
                        {{ contact.testimonials.length }} {{ contact.testimonials.length > 1 ? 'stories' : 'story' }}
                    </div>
                    <div>{{ new Date(contact.created_at).toLocaleDateString() }}</div>
                </div>
            </div>
        </div>
        <div v-else class="info h100 w100 d-flex aic jcc fdc" @click="$emit('updateView', 'magnets')">
            <div class="cp">
                No contacts yet? Share a magnet to start collecting stories from your contacts.
            </div>
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import ImageStock from '@/components/utils/ImageStock.vue';
export default {
    name: "contacts",
    data() {
        return {
            contacts: [],
            contactF: []
        };
    },

    components: {
        ImageStock
    },

    async created() {
        if (!this.$r.contacts) {
            await this.fetchContacts();
        } else {
            this.contacts = this.$r.contacts;
            this.contactF = this.$r.contacts;
        }
    },

    methods: {
        async fetchContacts() {
            let { data, error } = await supabase
                .from('contacts')
                .select('*, testimonials(*)')
                .eq('user', this.$r.user.id)
                .order('created_at', { ascending: false });

            let contacts = data
            if (error) {
                this.error = error.message;
            } else {
                this.contacts = contacts
                this.contactF = contacts
            }
        },
        searchContacts() {
            this.contactF = this.contacts.filter(contact => {
                return contact.name?.toLowerCase().includes(this.search.toLowerCase()) ||
                    contact.job_title?.toLowerCase().includes(this.search.toLowerCase()) ||
                    contact.company_name?.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
};
</script>

<style lang="scss">
html,
body {
    overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
.contact {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
}
</style>
